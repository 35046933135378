.header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  height: 60px;
  background-color: #fafcff;
  border-bottom: 1px solid #06021614;
}
.header-wrapper .header-logo {
  width: 85px;
}
.header-wrapper ul {
  display: flex;
  align-items: center;
  list-style: none;
  margin-bottom: 0px;
}
.header-wrapper ul .footer_sider_bar {
  position: relative;
  margin-right: 25px;
  margin-bottom: 0px;
  padding: 18px 0px;
  color: #060216b8;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.header-wrapper ul .footer_sider_bar.active {
  font-weight: 600;
}
.header-wrapper ul .footer_sider_bar.active::after {
  content: "";
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  height: 4px;
  background-color: #285ff7;
}
.header-wrapper .user-settings-main {
  display: flex;
  align-items: center;
}
.header-wrapper .user-settings-main .docs-cum {
  position: relative;
  margin-right: 30px;
  margin-bottom: 0px;
  padding: 15px 0px;
  color: #060216b8;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
}
.header-wrapper .user-settings-main .logout-wrap {
  position: relative;
}
.drop-backDrop {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 99;
  width: 100%;
  height: 100%;
}
.header-wrapper .user-settings-main .logout-wrap .drop-list {
  position: absolute;
  right: 0px;
  top: 60px;
  z-index: 999;
  display: flex;
  flex-direction: column;
  width: 253px;
  background-color: #ffffff;
  border: 1px solid #0602161f;
  border-radius: 8px;
  box-shadow: 0px 4px 14.7px 0px #0000000d;
}
.header-wrapper .user-settings-main .logout-wrap .drop-list .drop-header {
  display: flex;
  align-items: start;
  padding: 10px 15px;
}
.header-wrapper .user-settings-main .logout-wrap .drop-list .drop-header img {
  margin-right: 10px;
}
.header-wrapper
  .user-settings-main
  .logout-wrap
  .drop-list
  .drop-header
  .drop-title {
  color: #060216;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  margin-bottom: 0px;
}
.header-wrapper
  .user-settings-main
  .logout-wrap
  .drop-list
  .drop-header
  .drop-text {
  color: #060216;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  margin-bottom: 0px;
}
.header-wrapper .user-settings-main .logout-wrap .drop-list .drop-body {
  flex: 1 1 auto;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 1px solid #06021614;
  border-bottom: 1px solid #06021614;
}
.header-wrapper .user-settings-main .logout-wrap .drop-list .drop-item {
  display: flex;
  align-items: center;
  color: #060216;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  margin-bottom: 0px;
  padding: 10px 15px;
  transition: all 0.3s ease;
  cursor: pointer;
}
.header-wrapper .user-settings-main .logout-wrap .drop-list .drop-item img {
  margin-right: 10px;
}
.header-wrapper .user-settings-main .logout-wrap .drop-list .drop-item.logout {
  color: #db1919;
}
.header-wrapper .user-settings-main .logout-wrap .drop-list .drop-item:hover {
  background-color: rgba(0, 0, 0, 0.06);
}
.header-wrapper .user-settings-main .logout-wrap .logout-icon {
  width: 35px;
  height: 35px;
  cursor: pointer;
}
@media (max-width: 768px) {
  .mobile-header-active {
    position: relative;
    align-items: start;
    height: 135px;
    padding-top: 15px;
    border: 0;
    background-color: transparent;
  }
  .mobile-header-active .nav-links {
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
  }
  .mobile-header-active .nav-links ul {
    justify-content: center;
    padding-left: 0;
  }
  .mobile-header-active .nav-links ul .footer_sider_bar {
    font-weight: 400;
    border: 1px solid #0000001f;
    border-radius: 8px;
    padding: 5px 15px;
    margin-right: 0;
  }
  .mobile-header-active .nav-links ul li:nth-child(2) .footer_sider_bar {
    margin-left: 10px;
    margin-right: 10px;
  }
  .mobile-header-active .nav-links ul .footer_sider_bar.active {
    font-weight: 600;
    border-color: #285ff7;
    background-color: #e1e9ff;
  }
  .mobile-header-active .nav-links ul .footer_sider_bar.active::after {
    display: none;
  }
  .mobile-header-active .user-settings-main {
    display: none;
  }
}
/* ----------------------------- sub header ------- */
.sub-header-wrapper {
  padding-left: 20px;
  padding-right: 20px;
  margin: 20px 0px;
}
.sub-header-wrapper .menu-items-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #ffffff;
  border: 1px solid rgba(6, 2, 22, 0.08);
  border-radius: 16px;
}
.sub-header-wrapper .ant-menu {
  display: flex;
  align-items: center;
  width: auto;
  padding-left: 0px;
  list-style: none;
  margin-bottom: 0px;
  border-inline-end: 0 !important;
}
.sub-header-wrapper .ant-menu .ant-menu-item {
  position: relative;
  width: auto;
  height: auto;
  margin: 0px;
  padding: 10px 15px !important;
  color: rgba(6, 2, 22, 0.72);
  font-size: 14px;
  font-weight: 400;
  border-radius: 0;
  background-color: transparent;
  overflow: visible;
}
.sub-header-wrapper .ant-menu .ant-menu-item.ant-menu-item-selected {
  font-weight: 600;
}
.sub-header-wrapper .ant-menu .ant-menu-item.ant-menu-item-selected::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0px;
  inset-block: 95%;
  width: calc(100% - 30px);
  height: 4px;
  background-color: #285ff7;
  transform: translateX(-50%);
}
.btn-main {
  display: flex;
  align-items: center;
}
.sub-header-wrapper .menu-items-main .btn-main .side-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  margin-right: 15px;
  color: #285ff7;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 12px;
  outline: 0;
  cursor: pointer;
}
.sub-header-wrapper .menu-items-main .side-btn.outlined {
  border-color: #285ff7;
}
.sub-header-wrapper .menu-items-main .side-btn.filled {
  color: #ffffff;
  border-color: #285ff7;
  background-color: #285ff7;
}
.sub-header-wrapper .menu-items-main .btn-main .side-btn img {
  width: 16px;
  margin-right: 5px;
}
.dropDown .drop-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background-color: #eef0f6;
  border-radius: 50%;
  outline: 0;
  border: 1px solid #eef0f6;
  cursor: pointer;
}
.dropDown .drop-btn img {
  width: 20px;
}
.ant-menu-title-content a {
  text-decoration: none;
}
.plaground-header-description {
  margin: 4px 14px -6px;
  border-radius: 10px;
  padding-left: 10px;
  width: 70%;
  display: flex;
  align-items: center;
}
.footer_sider_bar {
  margin-bottom: 8px;
  color: #a6adb4;
  font-size: 15px;
  padding: 8px 16px;
  cursor: pointer;
  transition: color 0.4s;
  text-decoration: none;
}
.footer_sider_bar:hover {
  color: white;
}
.modal-container-Wrapper {
  position: relative;
  height: calc(100vh - 165px);
  padding-left: 20px;
  padding-right: 36px;
  padding-bottom: 20px;
}
.modal-container-Wrapper .setting-toggle-btn {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 10;
  height: 40px;
  padding-left: 10px;
  padding-right: 8px;
  border: 1px solid #06021614;
  border-radius: 36px 0px 0px 36px;
}
.modal-container-Wrapper .setting-toggle-btn:hover {
  border-color: #06021614 !important;
}
.model-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  height: 100%;
  margin-right: -15px;
  margin-left: -15px;
  overflow: hidden;
}

.content-col-4,
.content-col-8,
.content-col-12 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.content-col-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  height: 100%;
  padding-right: 0px;
}
.content-col-8 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
  height: 100%;
  transition: all 0.3s ease;
}
.content-col-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
  padding-left: 0px;
  padding-right: 0px;
  height: 100%;
  transition: all 0.3s ease;
}
.right-0 {
  right: 0%;
}
.right-100 {
  right: -100%;
}
.main-collapsed-siderbar-footer {
  position: absolute;
  bottom: 46px;
  left: 12px;
}
.main-siderbar-footer {
  position: absolute;
  bottom: 46px;
  left: 25px;
}
.profile-balance {
  margin: 50px;
  background-color: #f0f2f5;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
}
.comp-logo {
  display: flex;
  justify-content: center;
  margin: 30px;
}
.balance-main {
  display: flex;
  justify-content: center;
  align-items: center;
}
.balance-fontsize {
  font-size: 20px;
  font-weight: bold;
  color: #896acb;
}
.expand-collapse-button {
  font-size: 16px;
  width: 64px;
  height: 64px;
}

.aiml-modal-dialog .copy-code-modal .ant-modal-content {
  background-color: #eef0f6;
}
.aiml-modal-dialog .copy-code-modal .ant-modal-header {
  background-color: #eef0f6;
}
.aiml-modal-dialog .copy-code-tabs-main {
  background-color: #ffffff;
  border-radius: 10px;
}
.aiml-modal-dialog .copy-code-tabs-main .ant-tabs-nav-list {
  width: 100%;
  padding: 5px 15px;
  border-bottom: 1px solid #06021614;
}
.aiml-modal-dialog .copy-code-tabs-main .ant-tabs-nav-list .ant-tabs-tab {
  width: 33.3333333333%;
  height: 36px;
  justify-content: center;
  padding: 0;
  margin: 0;
  color: #060216 !important;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  border-radius: 10px;
}
.aiml-modal-dialog
  .copy-code-tabs-main
  .ant-tabs-nav-list
  .ant-tabs-tab.ant-tabs-tab-active {
  font-weight: 600;
  background-color: #f1f5ff;
}
.aiml-modal-dialog
  .copy-code-tabs-main
  .ant-tabs-nav-list
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: #060216 !important;
}
.aiml-modal-dialog .copy-code-tabs-main .ant-tabs-ink-bar {
  display: none;
}
.aiml-modal-dialog .copy-code-tabs-main .ant-tabs-content {
  height: 400px;
}
.aiml-modal-dialog .copy-code-tabs-main .ant-tabs-tabpane {
  height: 100%;
  overflow-y: hidden;
}
.aiml-modal-dialog .copy-code-tabs-main .tab-code-body {
  position: relative;
  padding: 0px 15px;
  height: 100%;
  overflow-y: scroll;
}
.aiml-modal-dialog .copy-code-tabs-main .tab-code-body::-webkit-scrollbar {
  width: 0px;
}
.aiml-modal-dialog .copy-code-tabs-main .tab-code-body > pre {
  margin-bottom: 0px;
}
.aiml-modal-dialog .copy-code-tabs-main .tab-code-body .code-block code {
  text-wrap: wrap;
}
.aiml-modal-dialog .copy-code-tabs-main .tab-code-body .copy-btn {
  position: absolute;
  top: 5px;
  right: 20px;
  border: 0;
  width: 35px;
  padding: 0;
  border-radius: 50%;
}
.aiml-modal-dialog .copy-code-tabs-main .tab-code-body .copy-btn img {
  width: 35px;
}

.aiml-modal-dialog .copy-code-tabs-main .tab-code-body .copied {
  position: absolute;
  top: 5px;
  right: 20px;
  display: flex;
  color: #285ff7;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  background-color: #e5ebfe;
  border-radius: 93px;
  padding: 5px 12px 5px 12px;
}

.aiml-modal-dialog .copy-code-tabs-main .tab-code-body .copied img {
  width: 20px;
  margin-left: 10px;
}

.aiml-modal-dialog .welcome-modal .ant-modal-content {
  padding: 30px;
  background: rgb(255, 255, 255);
  background-image: url("../Assets/Pattern.svg");
  background-position: right;
  background-repeat: no-repeat;
  overflow: hidden;
}
.aiml-modal-dialog .welcome-modal .ant-modal-content::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    145deg,
    rgba(255, 255, 255, 1) 34%,
    rgba(71, 79, 255, 0.3) 100%
  );
}
.aiml-modal-dialog .welcome-modal .ant-modal-body {
  position: relative;
  z-index: 10;
}
.aiml-modal-dialog .welcome-modal .ant-modal-content .ant-modal-close {
  display: none;
}
.aiml-modal-dialog .welcome-modal .welcome-header {
  margin-bottom: 20px;
}
.aiml-modal-dialog .welcome-modal .modal-title {
  color: #060216;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}
.aiml-modal-dialog .welcome-modal .w-text {
  color: #06021685;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}
.aiml-modal-dialog .welcome-modal .w-text span,
.aiml-modal-dialog .welcome-modal .w-text a {
  color: #285ff7;
  text-decoration: none;
}
.aiml-modal-dialog .welcome-modal .body-subtitle {
  color: #060216;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: 15px;
}
.aiml-modal-dialog .welcome-modal .text-icon-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.aiml-modal-dialog .welcome-modal .text-icon-wrap .w-text {
  margin-bottom: 0px;
}
.aiml-modal-dialog .welcome-modal .text-icon-wrap img {
  width: 20px;
  margin-right: 10px;
}
.aiml-modal-dialog .welcome-modal .ant-modal-footer {
  position: relative;
  z-index: 10;
  margin-top: 30px;
}
.aiml-modal-dialog .welcome-modal .ant-btn.ant-btn-default {
  display: none;
}
/* ---------- dashboard ------------ */

.dashboard-wrapper,
.privacy-n-termCondition {
  min-height: calc(100vh - 60px);
}
.dashboard-wrapper .dash-container,
.privacy-n-termCondition .termCondition-container {
  padding-left: 20px;
  padding-right: 20px;
  background-color: #eef0f6;
}
.dashboard-wrapper .dash-header {
  padding-top: 30px;
  padding-bottom: 40px;
}
@media (max-width: 768px) {
  .dashboard-wrapper .dash-header {
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 20px;
    border: 1px solid #285ff71f;
    border-radius: 12px;
  }
}
.dashboard-wrapper .page-title {
  color: #060216;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin-bottom: 5px;
}
.dashboard-wrapper .dash-header .page-subtitle {
  color: #060216;
  font-size: 32px;
  line-height: 40px;
  font-weight: 600;
  margin-bottom: 0;
}
.dashboard-wrapper .dash-header .page-subtitle span {
  color: #285ff7;
}
.dashboard-wrapper .dash-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: #285ff7;
  border-color: #285ff7;
  transition: all 0.3s ease;
}
.dashboard-wrapper .dash-btn::after {
  content: "";
  display: inline-block;
  width: 6px;
  height: 6px;
  margin-left: 5px;
  border-top: 1.5px solid #285ff7;
  border-right: 1.5px solid #285ff7;
  transform: rotate(45deg);
}
.dashboard-wrapper .explore-module {
  padding-bottom: 50px;
}
.dashboard-wrapper .explore-module .page-title,
.dashboard-wrapper .getMore-aiml .page-title {
  position: relative;
  color: #060216b8;
  padding-right: 10px;
  margin-bottom: 10px;
  text-transform: uppercase;
}
.dashboard-wrapper .explore-module .page-title::after,
.dashboard-wrapper .getMore-aiml .page-title::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  z-index: 1;
  width: 100%;
  height: 1px;
  border-top: 1px solid #06021614;
  transform: translateY(-50%);
}
.dashboard-wrapper .explore-module .page-title span,
.dashboard-wrapper .getMore-aiml .page-title span {
  position: relative;
  z-index: 9;
  padding-right: 10px;
  background-color: #eef0f6;
}
.dashboard-wrapper .explore-module .col-md-3:last-child {
  padding-right: 20px;
}
.dashboard-wrapper .explore-module .explore-card {
  min-height: 460px;
  padding: 0px;
  border-radius: 16px;
  /* border: 1px solid #06021614; */
}
.dashboard-wrapper .explore-module .explore-card:hover {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    140deg,
    rgba(255, 255, 255, 1) 40%,
    rgba(189, 211, 255, 1) 100%
  );
}
.dashboard-wrapper .explore-module .explore-card .ex-header {
  padding-bottom: 0px;
  border: 0;
}
.dashboard-wrapper .explore-module .explore-card .card-title {
  display: flex;
  align-items: end;
  color: #060216;
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
}
.dashboard-wrapper .explore-module .explore-card .card-title span {
  color: #8906f6;
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
  margin-left: 5px;
  padding: 0px 4px;
  border: 1px solid #8906f652;
  border-radius: 6px;
  background-color: #8906f61f;
}
.dashboard-wrapper .explore-module .explore-card .card-text {
  color: #060216b8;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}
.dashboard-wrapper .explore-module .explore-card .card-text .showMoreBtn {
  padding: 0;
  margin-left: 5px;
  outline: 0;
  border: 0;
  background: none;
  color: #060216;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  border-bottom: 1px solid #060216b8;
  cursor: pointer;
}

.dashboard-wrapper .explore-module .explore-card:hover .dash-btn {
  color: #ffffff;
  background-color: #285ff7;
}

.dashboard-wrapper .explore-module .explore-card:hover .dash-btn::after {
  color: #ffffff;
  border-color: #ffffff;
}
.dashboard-wrapper .explore-module .explore-card .tags-box {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  margin-top: 20px;
}
.dashboard-wrapper .explore-module .explore-card .tags-box .tags {
  display: flex;
  align-items: center;
  height: 24px;
  color: #060216;
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
  padding: 2px 8px;
  margin-bottom: 10px;
  margin-right: 10px;
  border: 1px solid #06021614;
  border-radius: 8px;
  background-color: #ffffff;
}
.dashboard-wrapper .explore-module .explore-card .tags-box .tags img {
  width: 15px;
  margin-right: 5px;
}
.dashboard-wrapper .explore-module .explore-card .aiml-card-footer {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 10;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}
.dashboard-wrapper .explore-module .explore-card:hover .aiml-card-footer {
  opacity: 1;
  visibility: visible;
}

.dashboard-wrapper .explore-module .explore-card .scroll-text-box {
  overflow: hidden;
  width: 100%;
  display: flex;
  align-items: center;
}
.dashboard-wrapper .explore-module .explore-card .scroll-text {
  color: #285ff7;
  font-size: 40px;
  line-height: 50px;
  font-weight: 400;
  white-space: nowrap;
  margin-right: 20px;
  animation: marquee 7s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-105%);
  }
}
.dashboard-wrapper .getMore-aiml {
  padding-bottom: 100px;
}
.dashboard-wrapper .getMore-aiml .col-md-6 {
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .dashboard-wrapper .getMore-aiml .col-md-6:nth-child(even) {
    padding-right: 20px;
  }
}

.dashboard-wrapper .getMore-aiml .getMore-card {
  flex-direction: row;
  align-items: center;
  border: 1px solid #06021614;
  border-radius: 16px;
}
@media (min-width: 968px) and (max-width: 1200px) {
  .dashboard-wrapper .getMore-aiml .getMore-card {
    min-height: 170px;
  }
}
@media (min-width: 1200px) and (max-width: 1490px) {
  .dashboard-wrapper .getMore-aiml .getMore-card {
    min-height: 135px;
  }
}
@media (max-width: 567px) {
  .dashboard-wrapper .getMore-aiml .getMore-card {
    flex-direction: column;
  }
}
.dashboard-wrapper .getMore-aiml .getMore-card .card-title {
  color: #060216;
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 10px;
}
.dashboard-wrapper .getMore-aiml .getMore-card .card-text {
  width: 80%;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #060216b8;
}
.dashboard-wrapper .getMore-aiml .getMore-card .btn-wrapper {
  padding-right: 20px;
}
.dashboard-wrapper .getMore-aiml .getMore-card .dash-btn:hover {
  color: #ffffff;
  background-color: #285ff7;
}
.dashboard-wrapper .getMore-aiml .getMore-card .dash-btn:hover::after {
  color: #ffffff;
  border-color: #ffffff;
}
@media (max-width: 567px) {
  .dashboard-wrapper .getMore-aiml .getMore-card .btn-wrapper {
    width: 100%;
    padding-left: 20px;
    padding-bottom: 15px;
  }
}
.dashboard-wrapper .dash-footer {
  padding-bottom: 40px;
}
.dashboard-wrapper .dash-footer .footer-title {
  color: #1d243452;
  font-size: 35px;
  line-height: 40px;
  font-weight: 600;
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .dashboard-wrapper .dash-footer .footer-title {
    font-size: 48px;
    line-height: 58px;
  }
}
.dashboard-wrapper .dash-footer .footer-btn-box {
  display: flex;
  align-items: center;
}
@media (max-width: 768px) {
  .dashboard-wrapper .dash-footer .footer-btn-box {
    flex-direction: column;
    align-items: flex-start;
  }
}
.dashboard-wrapper .dash-footer .footer-copy-text {
  color: #1d243452;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  margin-bottom: 0px;
  margin-right: 30px;
}
@media (max-width: 768px) {
  .dashboard-wrapper .dash-footer .footer-copy-text {
    margin-bottom: 15px;
  }
}
.dashboard-wrapper .dash-footer .footer-btn {
  color: #1d243452;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  padding: 0;
  outline: 0;
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #1d243452;
}
.dashboard-wrapper .dash-footer .footer-btn:first-child {
  margin-right: 30px;
}
/* ------------ privacy and terms and condition ----------- */

.privacy-n-termCondition .termCondition-container {
  padding-top: 25px;
  padding-bottom: 35px;
}
.privacy-n-termCondition .aiml-card {
  border-radius: 16px;
  box-shadow: 0px 2px 22px 0px #06021612;
}
.privacy-n-termCondition .page-title {
  color: #060216;
  font-size: 32px;
  line-height: 30px;
  font-weight: 600;
}
.privacy-n-termCondition .content-title {
  color: #060216;
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
}
.privacy-n-termCondition .content-subtitle {
  color: #060216;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 0px;
}
.privacy-n-termCondition .content-text,
.privacy-n-termCondition ul li {
  color: #060216;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}
.privacy-n-termCondition .content-text span {
  font-weight: 600;
}

.privacy-n-termCondition .aiml-primary-btn {
  display: flex;
  align-items: center;
}
.privacy-n-termCondition .aiml-primary-btn img {
  margin-right: 5px;
}
