.your-organisation .ailm-card-header {
  padding: 10px 0px;
  padding-bottom: 20px;
  border-bottom: 0;
}

.your-organisation .aiml-card-body {
  padding: 0px;
}

.your-organisation .ailm-card-header .card-title {
  color: rgba(6, 2, 22, 1);
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

.aiml-card-body .fInput-group .input-label {
  color: rgba(6, 2, 22, 0.72);
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  margin-bottom: 5px;
}

.your-organisation .card-footer {
  padding: 10px 0px;
}

.your-organisation .card-footer .aiml-primary-btn {
  margin-right: 10px;
}

.ant-btn-send-invite {
  background-color: #285ff7;
  color: white !important;
  border-radius: 12px;
}

.ant-btn-cancel {
  border-radius: 12px;
}

.copy-key-position {
  right: 15px;
  bottom: 15px;
  font-size: 12px;
}

.aiml-table .ant-table-thead > tr > th {
  background-color: #ffff;
  font-weight: 500;
  color: rgba(6, 2, 22, 0.52);
}

.aiml-table .ant-table-tbody > tr > td {
  font-weight: 500;
  color: #060216;
}

.email-error {
  color: #db1919;
}

.aiml-modal-dialog .aiml-revoke-modal .ant-btn.ant-btn-primary {
  color: white !important;
  border: 1px solid #db1919 !important;
  background-color: #db1919 !important;
}

.ant-button-create-key {
  background-color: #285ff7 !important;
  color: white !important;
}

.ant-button-create-key:hover {
  background-color: #285ff7;
  color: rgb(114, 111, 111) !important;
}

.delete-icon-img {
  width: 11px;
  height: 13px;
}

.aiml-table .ant-table-pagination {
  display: flex;
  justify-content: space-between;
}

.aiml-table .ant-pagination {
  order: 1;
}

.aiml-table .ant-pagination-options {
  order: 2;
  margin-left: auto;
}

.plans-billing {
  background-color: #eef0f6;
}

.plans-billing-plans-container {
  border-radius: 16px;
}

.free-plan-section {
  width: 55%;
}

.pricing-card {
  border: 1px solid rgba(6, 2, 22, 0.08);
  border-radius: 16px;
  transition: all 0.3s ease;
}
.pricing-card:hover {
  background-image: linear-gradient(to bottom right, #ffffff, #bdd3ff);
}
.pricing-card .aiml-btn {
  width: 100%;
  border: 0;
  background-color: rgba(6, 2, 22, 0.12);
  transition: all 0.3s ease;
}

.pricing-card:hover .aiml-btn {
  color: #ffff !important;
  background-color: #285ff7 !important;
}
.your-current-plan-button {
  width: 396px;
  border-radius: 12px;
  background-color: rgba(6, 2, 22, 0.12);
}

.upgrade-plan-button {
  border-radius: 12px;
  width: 396px;
}

.upgrade-plan-points {
  font-size: 12px;
  font-weight: 500;
  color: rgba(6, 2, 22, 0.52);
}

.upgrade-plan-values {
  color: #060216;
  font-size: 16px;
  font-weight: 500;
}

.upgrade-plan-heading {
  color: #060216;
  font-size: 18px;
  font-weight: 600;
}
