::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-track {
  background: #eef0f6cc;
}
::-webkit-scrollbar-thumb {
  background: #285ff7;
  border-radius: 5px;
}
@font-face {
  font-family: "Onest";
  src: url("./Assets//fonts/Onest-Regular.ttf") format("ttf");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Onest";
  src: url("./Assets//fonts/Onest-Medium.ttf") format("ttf");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Onest";
  src: url("./Assets//fonts/Onest-SemiBold.ttf") format("ttf");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Onest";
  src: url("./Assets//fonts/Onest-Bold.ttf") format("ttf");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Onest";
  src: url("./Assets//fonts/Onest-ExtraBold.ttf") format("ttf");
  font-weight: 800;
  font-style: normal;
}
* {
  font-family: "Onest", sans-serif;
}
html,
body {
  font-weight: 400;
}
.ant-layout {
  margin-left: 0px !important;
  background: rgba(238, 240, 246, 1);
  background-size: cover;
  min-height: 100vh;
  height: 100%;
}
.h-full {
  height: 100%;
}
.backColor {
}
/* .ant-layout-sider{
    height: none !important;
} */

/* --- Modal theme --- */

/* Define keyframes for the open and close animations */

/* Apply the animations to the modal */
.aiml-modal-dialog {
  animation-duration: 0.3s;
  animation-fill-mode: both;
}

.aiml-modal-dialog-appear,
.aiml-modal-dialog-enter {
  animation-name: modalOpen;
}

.aiml-modal-dialog-leave {
  animation-name: modalClose;
}
@keyframes modalOpen {
  from {
    opacity: 0;
    transform: translateY(-10%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes modalClose {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10%);
  }
}
.aiml-modal-dialog .ant-modal-content {
  padding: 20px;
  border-radius: 20px;
}
.aiml-modal-dialog .ant-modal-content .ant-modal-title {
  color: #060216;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 15px;
}
.aiml-modal-dialog
  .ant-modal-content
  .ant-modal-footer
  .ant-btn.ant-btn-default {
  height: 38px;
  padding: 5px 15px;
  color: rgba(6, 2, 22, 0.52);
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  background-color: #ffffff;
  border: 1px solid rgba(6, 2, 22, 0.22);
  border-radius: 12px;
  outline: 0;
  cursor: pointer;
}

.aiml-modal-dialog
  .ant-modal-content
  .ant-modal-footer
  .ant-btn.ant-btn-primary {
  height: 38px;
  padding: 5px 15px;
  color: #ffffff;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  background-color: #285ff7;
  border: 1px solid #285ff7;
  border-radius: 12px;
  outline: 0;
  cursor: pointer;
}
.aiml-modal-dialog .date-modal .ant-modal-content .ant-modal-body .ant-picker {
  width: 100%;
}
.aiml-modal-dialog
  .date-modal
  .ant-modal-content
  .ant-modal-body
  .ant-picker
  .ant-picker-input
  input {
  text-align: center;
}

.aiml-modal-dialog {
  backdrop-filter: blur(2px);
}
/* ---------------------- modal end -------------------------- */
.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.col-md-3,
.col-md-6,
.col-lg-6,
.col-xl-3 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
@media (min-width: 768px) {
  .col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
}
@media (min-width: 967px) {
  .col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media (min-width: 1200px) {
  .col-xl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
}
.aiml-card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  transition: all 0.3s ease;
}
.aiml-card .ailm-card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  margin-bottom: 0;
  border-bottom: 1px solid rgba(7, 3, 23, 0.078);
}
.aiml-card .aiml-card-body {
  height: 100%;
  flex: 1 1 auto;
  padding: 1rem 20px;
}
.aiml-card .card-footer {
  position: relative;
  padding: 1rem 1rem;
}

.fInput-group {
  margin-bottom: 20px;
}
.fInput-group .form-input {
  display: block;
  width: 100%;
  height: 42px;
  color: rgba(6, 2, 22, 1);
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  border: 1px solid rgba(6, 2, 22, 0.12);
  border-radius: 12px;
}
.fInput-group .input-label {
  color: rgba(6, 2, 22, 0.72);
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  margin-bottom: 5px;
}
.fInput-group .ant-select {
  height: 42px !important;
}
.fInput-group .ant-select-selector {
  width: 100% !important;
  height: 42px !important;
  color: rgba(6, 2, 22, 1);
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  border: 1px solid rgba(6, 2, 22, 0.12) !important;
  border-radius: 12px;
}
.aiml-primary-btn {
  height: 38px;
  padding: 5px 15px;
  color: #ffffff;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  background-color: #285ff7;
  border: 1px solid #285ff7;
  border-radius: 12px;
  outline: 0;
  cursor: pointer;
}
.aiml-btn {
  height: 38px;
  padding: 5px 15px;
  color: rgba(6, 2, 22, 0.52);
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  background-color: #ffffff;
  border: 1px solid rgba(6, 2, 22, 0.22);
  border-radius: 12px;
  outline: 0;
  cursor: pointer;
}
.flex {
  display: flex;
}
.items-end {
  align-items: end;
}
