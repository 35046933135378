.model-setting {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: 100%;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(6, 2, 22, 0.078);
  border-radius: 16px;
}

.model-setting .model-setting-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  margin-bottom: 0;
  border-bottom: 1px solid rgba(7, 3, 23, 0.078);
}

.model-setting .model-setting-header .model-heading {
  font-family: "Onest", sans-serif;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
}
.model-setting .model-setting-header .accordian-image {
  z-index: 10;
  cursor: pointer;
}
.model-setting .modal-setting-body {
  height: 100%;
  flex: 1 1 auto;
  padding: 10px 0px;
  overflow: hidden;
}
.model-setting-innerBody {
  height: 100%;
  padding: 0px 20px;
  overflow-y: auto;
}
.model-setting .modal-setting-body .model-selection-subheader {
  display: flex;
  align-items: center;
  font-family: "Onest", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: rgba(6, 2, 22, 0.52);
  margin-bottom: 8px;
  padding-top: 15px;
}
.model-setting .modal-setting-body .model-selection-subheader img {
  margin-left: 10px;
}
.model-setting .modal-setting-body .modelSelection {
  width: 100%;
  height: 48px;
}

.model-setting .modal-setting-body .modelSelection .ant-select-selector {
  border: 1px solid rgba(6, 2, 22, 0.12);
  background: #ffffff;
  border-radius: 12px !important;
}

.system-prompt-title {
  display: flex;
  align-items: center;
  font-family: "Onest", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: rgba(6, 2, 22, 0.52);
  margin-bottom: 20px;
}
.system-prompt-dropdown .prompt-box ul {
  list-style: none;
  padding: 0px;
}
.system-prompt-dropdown .prompt-box ul .prompt-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
}
.system-prompt-dropdown .prompt-box ul .prompt-item .item-title {
  color: #060216;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  margin-bottom: 0px;
}
.system-prompt-dropdown .prompt-box ul .prompt-item .item-text {
  color: #06021685;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  margin-bottom: 0px;
}
.model-setting .modal-setting-body .parameters-title {
  font-family: "Onest", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: rgba(40, 95, 247, 1);
  margin-bottom: 0px;
}

:where(.css-dev-only-do-not-override-kghr11)
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header {
  padding: 0;
}

.model-selection-collapse {
  border: none;
  background-color: white;
}
.model-selection-collapse .ant-collapse-content .ant-collapse-content-box {
  /* height: 150px; */
  /* overflow-y: auto; */
}
.main-parameters,
.main-voice-container {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}
.parameters-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.ant-slider-horizontal .ant-slider-rail {
  width: 100%;
  height: 8px;
  background-color: #f0f0f0;
  border-radius: 6px;
}

.ant-slider-horizontal .ant-slider-track {
  background-color: #0f6fff;
  height: 8px;
  border-radius: 6px;
}

.ant-slider-handle {
  width: 40px;
  height: 40px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.ant-slider-handle:hover {
  border-color: #e74c3c;
}

.ant-slider-handle::after {
  content: "";
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  width: 15px !important;
  height: 15px !important;
  background-color: #ffffff;
  box-shadow: 0 0 0 2px #91caff;
  border-radius: 50%;
  cursor: pointer;
  transition: inset-inline-start 0.2s, inset-block-start 0.2s, width 0.2s,
    height 0.2s, box-shadow 0.2s;
}

.system-prompt-dropdown {
  display: flex;
  flex-direction: column;
  padding: 0;
  padding-top: 25px;
  border-top: 1px solid #0602161f;
}

.options-list {
  list-style: none;
  padding: 0;
}

.options-list li {
  cursor: pointer;
  padding: 8px 16px;
  font-family: "Onest", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  margin-left: -14px;
}

.parameter-text-edit {
  background-color: rgb(230, 247, 255);
  padding: 2px;
  border-radius: 5px;
}
.parameter-edit-field {
  width: 60px;
  font-size: 12px;
}
.model-setting .modification-container .ant-collapse-header {
  padding: 25px 0px !important;
  border-top: 1px solid rgba(7, 3, 23, 0.078);
}
.model-setting
  .modal-setting-body
  .modification-container
  .ant-collapse-content-box {
  padding: 0;
}
.system-instructions {
  padding-top: 20px;
  padding-bottom: 15px;
}
.system-instructions-label {
  display: flex;
  align-items: center;
  font-family: "Onest", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 10px;
  color: rgba(6, 2, 22, 0.52);
}
.system-instructions-label .image-after-label {
  margin-left: 10px;
}
.model-setting .system-instructions-textarea {
  width: 100%;
  height: 112px !important;
  border-radius: 12px;
  border: 1px solid rgba(6, 2, 22, 0.12) !important;
  border-bottom: none;
  resize: none;
  color: rgba(6, 2, 22, 0.52);
}

.model-setting .system-instructions-textarea[disabled] {
  background-color: white;
}

.edit-system-prompt {
  display: flex;
  justify-content: space-between;
}
.main-add-system-prompt-fields,
.edit-system-prompt-fields {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  height: 20vh;
}
.add-system-prompt-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  cursor: pointer;
  border: none;
  background: none;
}
.system-prompt-edit {
  position: absolute;
  left: 100%;
  margin-left: 5px;
  cursor: pointer;
  opacity: 0;
}

.collapse-model-modifications {
  background: white;
  border: none;
  border-top: none;
}

.ant-collapse .ant-collapse-content {
  border-top: none !important;
}
