.message {
  transition: transform 0.5s ease-in-out;
}

.chat-container {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: 100%;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(6, 2, 22, 0.078);
  border-radius: 16px;
}
.prompt-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  margin-bottom: 0;
  border-bottom: 1px solid rgba(7, 3, 23, 0.078);
}
.prompt-header .chat-title {
  margin-bottom: 0px;
  color: #060216;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}
.prompt-header .clear-chat-container {
  cursor: pointer;
}

.prompt-header .clear-chat-container .clear-chat {
  display: inline-block;
  font-family: "Onest", sans-serif;
  color: #060216;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.chats-body-container {
  height: 100%;
  flex: 1 1 auto;
  padding: 1rem 90px 0px 90px;
  overflow-y: hidden;
}
.chats-body-container .welcome-body {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.chats-body-container .welcome-body .welcome-text {
  color: #060216;
  font-size: 32px;
  line-height: 40px;
  font-weight: 600;
  text-align: center;
}
.chats-body-container .welcome-body .welcome-text span {
  color: #285ff7;
}
.chats-body-container .chat-body {
  height: 100%;
  overflow-y: auto;
  padding-right: 15px;
}
.chats-body-container .user-message,
.chats-body-container .bot-message {
  display: flex;
  align-items: start;
  margin-bottom: 45px;
}
.chats-body-container .user-logo,
.chats-body-container .bot-message .user-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 30px;
  height: 30px;
  border: 1px solid #060216;
  border-radius: 50%;
}
.chats-body-container .bot-message .user-logo {
  width: 35px;
  height: 35px;
  border-color: rgba(191, 87, 255, 0.32);
  background-color: rgba(137, 6, 246, 0.12);
}
.chats-body-container .user-logo img,
.chats-body-container .bot-message .user-logo img {
  width: 30px;
}
.chats-body-container .bot-message .user-logo img {
  width: 35px;
}
.chats-body-container .user-name-main,
.chats-body-container .bot-message .user-name-main {
  flex-grow: 1;
  margin-left: 15px;
}
.chats-body-container .user-name-main .user-name,
.chats-body-container .bot-message .user-name-main .user-name {
  color: #06021685;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
}
.chats-body-container .user-message .user-name-main p,
.chats-body-container .bot-message .user-name-main .bot-msg-container {
  padding: 15px;
  color: #060216;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  background-color: rgba(238, 240, 246, 0.8);
  border-radius: 0px 12px 12px 12px;
  margin-bottom: 0px;
}
.chats-body-container .bot-message .user-name-main .bot-msg-container {
  background-color: rgba(40, 95, 247, 0.08);
}
.input-container-footer {
  padding: 1rem 90px;
}

.input-container-footer .input-text-area {
  width: 100%;
  height: 90px;
  border-radius: 12px 0px 0px 0px;
  opacity: 0px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(6, 2, 22, 0.08);
  margin-top: auto;
}
.input-container-footer .Chat-text-field {
  width: 100%;
  height: 95px;
  padding: 15px;
  resize: none;
  border: 1px solid rgba(6, 2, 22, 0.078);
  border-radius: 12px;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.08);
  outline: 0;
}
.input-container-footer .Chat-text-field:focus {
  border: 1px solid rgba(6, 2, 22, 0.078);
}
.input-container-footer .textarea-wrapper {
  position: relative;
}
.input-container-footer .textarea-wrapper .listen-text-box {
  position: absolute;
  top: 15px;
  left: 15px;
  display: flex;
  width: calc(100% - 190px);
}
.input-container-footer .textarea-wrapper .listening-text {
  display: flex;
  align-items: end;
  color: #06021685;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  padding: 5px 10px;
  margin-bottom: 0px;
  border-radius: 26px;
  background-color: #edf4ff;
}
.input-container-footer .textarea-wrapper .listening-text img {
  width: 15px;
  margin-left: 10px;
}
.input-container-footer .textarea-wrapper .query-button-container {
  position: absolute;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: row;
}
.input-container-footer .textarea-wrapper .query-button-container .mic-logo {
  position: absolute;
  top: -20px;
  right: -5px;
  width: 30px;
  padding: 0;
  height: 30px;
  border: 0;
  border-radius: 50%;
  outline: 0;
  background: transparent;
}

.query-button-container .mic-logo.listening::after {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  width: 30px;
  height: 30px;
  border: 1.5px solid #285ff7;
  border-radius: 50%;
  background: transparent;
  transform: scale(1);
  animation: zoomIn 1s infinite linear;
}
@keyframes zoomIn {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.query-button-container .mic-logo img {
  width: 20px;
}
.query-button-container .btn-text-box {
  display: flex;
  align-items: flex-end;
}
.query-button-container .btn-text-box .word-limit {
  margin-right: 20px;
}
.query-button-container .btn-text-box .chat-send-tooltip {
  display: flex;
  align-items: center;
  background-color: rgba(40, 95, 247, 1);
  color: rgba(255, 255, 255, 1);
  margin-top: 15px;
  cursor: pointer;
  font-family: "Onest", sans-serif;
  padding: 18px 14px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  border-radius: 12px;
}
.query-button-container .btn-text-box .chat-send-tooltip img {
  margin-left: 8px;
}
.delete-chat {
  margin-top: 10px;
  margin-top: -4px;
  margin-right: 4px;
}
.horizontal-line {
  width: 1013px;
  border: 1px 0px 0px 0px;
  opacity: 0px;
  border: 1px solid rgba(6, 2, 22, 0.08);
}

.user-message-container {
  display: flex;
  justify-content: flex-end;
}

.user-message-container .user-message {
  margin-left: 10px;
}

.user-icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-color: #1890ff;
  color: white;
  border-radius: 50%;
  text-align: center;
  line-height: 24px;
  font-size: 14px;
  margin-right: 5px;
  position: relative;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.chat-message {
  display: inline-block;
  margin: 2px;
  border-radius: 8px;
  max-width: 80%;
  word-wrap: break-word;
}

.chat-response {
  padding: 10px;
  margin: 5px;
  border-radius: 8px;
  overflow: hidden;
}

.chat-box-reset {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  border: 2px solid #3498db;
  border-radius: 50%;
  margin-top: 4px;
  margin-left: 7px;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.chat-box-reset:hover {
  transform: scale(1.1);
  border-color: #e74c3c;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left: 4px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.streaming-switch-container {
  display: flex;
  position: fixed;
  background-color: white;
  padding: 8px;
  border-radius: 27px;
}

/* .chat-details h4,
.chat-details span {
  display: inline-block;
  vertical-align: middle;
} */

.custom-span {
  cursor: pointer;
  display: inline-block;
  margin-left: 10px;
}
